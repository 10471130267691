<template>
    <div
        class="chat-content flex grow flex-col gap-6 overflow-auto break-words px-8 py-6"
        :class="{ 'scroll-smooth': initialize }"
        ref="chatContent"
    >
        <div v-if="isFetching" class="flex justify-center">
            <base-loading />
        </div>

        <template v-for="item in emails" :key="item.id">
            <accountant-email-sender-message
                v-if="
                    item.from === room.chat_room_email?.mail_connection?.email
                "
                :item="item"
            />

            <accountant-email-receiver-message :item="item" v-else />
        </template>
    </div>
</template>

<script setup>
import { ECHO_EVENTS } from '@tenant/utils/constants'
import sendSound from '@tenant/assets/audio/sendMessage.mp3'
import receiveSound from '@tenant/assets/audio/receiveMessage.mp3'
import { useEmails } from '@tenant/composables/use-chat'

const props = defineProps({
    room: {
        type: Object,
        required: true,
    },
})

const emitter = useEmitter()
const { newEmail } = useConversation()

const audioSend = new Audio(sendSound)
const audioReceive = new Audio(receiveSound)

const { updateConversation } = useConversation()
const {
    initialize,
    chatContent,
    emails,
    isFetching,
    scrollContentToBottom,
    isBottomContent,
} = useEmails(props.room)

onMounted(() => {
    emitter.on('sent-message', onSentMessage)
})

onUnmounted(() => {
    emitter.off('sent-message', onSentMessage)
})

useListenEmitEcho(ECHO_EVENTS.EMAIL_MESSAGE, ({ message, chatRoom }) => {
    if (chatRoom.id !== props.room.id) {
        return
    }

    newEmail(props.room, message)
    audioReceive.play()

    if (isBottomContent()) {
        scrollContentToBottom()
    }
})

const onSentMessage = ({ room_id, message, chat_room_email }) => {
    if (room_id !== props.room.id) {
        return
    }

    audioSend.play()
    scrollContentToBottom()
    updateConversation(
        {
            ...props.room,
            chat_room_email: chat_room_email,
        },
        message
    )
}
</script>
